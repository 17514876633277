export const FEED_ITEM_WRAPPER_EDITION_FRAGMENT = `
    fragment feedItemWrapperEditionFragment on FeedItemWrapper {
        uid
        aggregateKey
        pinnedInGlobal
        initiator {
            __typename
            ...on SubEdition {
                name
                uid
                logoFileResource {
                    uid
                    schemaCode
                    path
                }
                bannerFileResource {
                    uid
                    schemaCode
                    path
                }
            }
        }
        feedItems (orderBy: createdTime_desc){
            action
            createdTime
            triggered {
                __typename
                ...on CommunityUser {
                    name
                    uid
                }
                ...on Exhibitor {
                    name
                    uid
                }
                ...on CommunityUserInterest {
                    uid
                    interest {
                        uid
                        name
                    }
                }
                ... on FileResource {
                    uid
                    path
                }
            }
        }
        updatedTimestamp
        createdTimestamp
}`;
